import axios from "axios";
import qs from "query-string";
import { apiUrl, apiKey,hdfc_request_encrypt_code } from "./constants";
import { toast } from "react-toastify";
import AirportData from "./airports_iata.json";
import { timeout } from "q";
import _, { isEmpty } from 'lodash';
import React, { Component } from 'react';
import FlightsCards from './routes/flightsearch/FlightsCards'
import {
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import {
  convertAirportOption
} from './staticData';
import moment from 'moment';
import momentTZ from "moment-timezone"


export function partial(fn, ...presetArgs) {
  return function partiallyApplied(...laterArgs) {
    return fn(...presetArgs, ...laterArgs);
  };
}

export const addressObjectToText = addressObject => {
  const { address, city, country, state, zipCode } = addressObject || {};

  const addressArr = [address, city, country, state, zipCode];

  let textAddress = addressArr.reduce((field1, field2, index) => {
    return `${field1 || ""} ${(field2 &&
      `${field2.trim()}${index !== addressArr.length - 1 ? "," : ""}`) ||
      ""}`;
  });

  return textAddress.trim();
};

export const convertToOptionsArray = (list = "", valueField, labelField) => {
  return list.map(item => ({
    value: item[valueField] || "",
    label:
      labelField == "firstName"
        ? item[labelField] + " " + item["lastName"]
        : item[labelField]
  }));
};

export function logOut() {
  return new Promise((res, rej) => {
    localStorage.removeItem("pdc-user");
    res(true);
  });
}

export function isLoggedIn() {
  let session = getObject("pdc-user");
  let token = session && session.token;

  return token;
}

export const rememberMe = () => {
  let remember = JSON.parse(window.atob(getObject("pdc-remember-user") || "") || null)
  return remember
}

export function saveRememberUser(value) {
  if (window && window.localStorage) {
    let newVal = window.btoa(JSON.stringify(value))

    return window.localStorage.saveObject("pdc-remember-user", newVal);
  }

  return null;
}

export function getUserObject() {
  let session = getObject("pdc-user");
  return session
}

export function logout() {
  saveUser(null);
  return new Promise((res, rej) => res(true));
}

export function getHeaders() {
  let user = getUser();
  return {
    token: `${(user && user.token) || ""}`,
    "content-type": "application/json"
  };
}

export function getUser() {
  if (window && window.localStorage) {
    return window.localStorage.getObject("pdc-user");
  }

  return null;
}

export function getUserDetails() {
  if (window && window.localStorage) {
    const data = parseJwt(window.localStorage.getObject("pdc-user").token);
    return data;
  }

  return null;
}

export function isAdmin() {
  const user = getUserDetails();
  if (!user) return false;

  const isAdmin =
    (user.roles && user.roles.find(role => role === "admin")) || false;

  if (isAdmin) return true;
  else return false;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export function saveUser(value) {
  if (window && window.localStorage) {
    return window.localStorage.saveObject("pdc-user", value);
  }

  return null;
}

export function saveObject(key, value) {
  if (window && window.localStorage) {
    window.localStorage.saveObject(key, value);
  }
}

export function removeObject(key) {
  if (window && window.localStorage) {
    window.localStorage.removeItem(key);
  }
}

export function getObject(key) {
  if (window && window.localStorage) {
    return window.localStorage.getObject(key);
  }

  return null;
}

export function getLanguage() {
  if (window && window.localStorage) {
    return window.localStorage.getObject("language") || "EN";
  }

  return "EN";
}

export function setLanguage(language) {
  if (window && window.localStorage) {
    return window.localStorage.saveObject("language", language);
  }

  return null;
}

export function generateUrl(path) {
  if (path.includes("http")) {
    return path;
  }
  return apiUrl + path;
}

export function getIntersection(
  FilterFromArr,
  FilterWithArr,
  matchingProperty
) {
  if (FilterFromArr && FilterWithArr && matchingProperty) {
    return [...FilterFromArr].filter(element1 =>
      FilterWithArr.some(element2 => {
        return element1[matchingProperty] == element2[matchingProperty];
      })
    );
  }
}

export function apiReq(endPoint, data, method, headers, requestOptions = {}) {
  return new Promise((res, rej) => {
    headers = {
      ...getHeaders(),
      ...headers,

    };
    if (method == "get" || method == "delete") {
      data = {
        ...requestOptions,
        params: data,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        headers
      };
    }

    axios[method](endPoint, data, { headers })
      .then(result => {
        let { data } = result;

        if (data.status === false) {
          return rej(data);
        }

        return res(data);
      })
      .catch(err => {
        if(err?.response?.status == 401){
          logOut();
        }
        return rej(err);
      });
  });
}

export function apiPost(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "post", headers);
}

export function apiDelete(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "delete", headers);
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(generateUrl(endPoint), data, "get", headers, requestOptions);
}

export function apiPut(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "put", headers);
}

export function multiPartData(data) {
  let multiPart = new FormData();

  for (let prop in data) {
    multiPart.append(prop, data[prop]);
  }

  return multiPart;
}

export function randomString(len = 5) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function getTranslation(text, translations = {}) {
  return translations[text] || "";
}

export function isAuthenticated() {
  if (!isLoggedIn()) {
    toast.error("Please login to send message.", {
      position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }

  return true;
}

export function trimText(text, len = 20) {
  if (text.length <= len) return text;
  return text.substring(0, len - 1) + "...";
}

export function getOnlyIATAAirports() {
  return AirportData.filter(code => code.iata);
}


export function timeConvert(n) {
  // console.log(n, " this is n")
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours).toString();
  var minutes = ((hours - rhours) * 60).toString();
  var rminutes = Math.round(minutes);
  if (!rhours) {
    return rminutes + "min";
  }
  return `${rhours.length == "1" ? `0${rhours}h` : `${rhours}h`}  ${rminutes.length == "1" ? `0${rminutes}m` : `${rminutes}m`}`;
}

export function timeDifference(d1, d2) {
  var diff = Math.abs(new Date(d2) - new Date(d1));
  var minutes = Math.floor((diff/1000)/60);
  return minutes;
}


export function getSystemIP() {
  //return axios.get('http://ip.jsontest.com/?callback=?').then(res => res)
  return axios.get('https://jsonip.com').then(res => res.data)
}

export function refactorIp(info) {
  let address = JSON.parse(JSON.stringify(info, null, 2))
  return address.ip
}

export function countArray(val, increment = 0) {
  let arr = [...Array(val)].map((_, i) => { return { value: i + increment, label: i + increment } })
  // .map((data, index) => { return { value: index, label: index } })
  return arr || []
}

export function getUpperCase(string) {
  let tokens = string.split(" ");
  tokens = tokens.map(token => {
    return _.capitalize(token);
  })
  return tokens.join(" ");
}

//------------------------------------Flights ----------------------------------------------


//functions used for generating object of fare and passenger details for LCC and Non LCC
export const generatePassengerObjectLCC = (data, flightBookedResults, key, meals, baggage, gstDetails = {}, seats) => {

  let arr = [];
  let adultFilter = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "1") : [] || []

  let updatedAdult = adultFilter && adultFilter.map((item, index) => {
    return {
      ...item,
      "MealDynamic": meals && meals.adult && meals.adult.length ? meals.adult.map((item, i) => {
        return { ...(item && item[index]) };
      }) : [],
      "Baggage": baggage && baggage.adult && baggage.adult.length ? baggage.adult.map((item, i) => {
        return { ...(item && item[index]) };
      }) : [],
      "SeatDynamic": seats && seats.adult && seats.adult.length ? seats.adult.map((item, i) => {
        return { ...(item && item[index]) };
      }) : []
    }
  })

  let childFilter = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "2") : [] || []

  let updatedChild = childFilter && childFilter.map((item, index) => {
    return {
      ...item,
      "MealDynamic": meals && meals.children && meals.children.length ? meals.children.map((item, i) => {
        return { ...(item[index]) };
      }) : [],
      "Baggage": baggage && baggage.children && baggage.children.length ? baggage.children.map((item, i) => {
        return { ...(item[index]) };
      }) : [],
      "SeatDynamic": seats && seats.children && seats.children.length ? seats.children.map((item, i) => {
        return { ...(item[index]) };
      }) : []
    }
  })

  let updatedInfant = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "3") : [] || [];

  let totalPassengers = [
    ...updatedAdult.map(adult => ({ ...adult, SeatDynamic: adult.SeatDynamic && adult.SeatDynamic.length ? adult.SeatDynamic.filter(adult => !(isEmpty(adult))) : [], MealDynamic: adult.MealDynamic && adult.MealDynamic.length ? adult.MealDynamic.filter(adult => !(isEmpty(adult))) : [], Baggage: adult.Baggage && adult.Baggage.length ? adult.Baggage.filter(adult => !(isEmpty(adult))) : [] })),
    ...updatedChild.map(child => ({ ...child, SeatDynamic: child.SeatDynamic && child.SeatDynamic.length ? child.SeatDynamic.filter(child => !(isEmpty(child))) : [], MealDynamic: child.MealDynamic && child.MealDynamic.length ? child.MealDynamic.filter(child => !(isEmpty(child))) : [], Baggage: child.Baggage && child.Baggage.length ? child.Baggage.filter(child => !(isEmpty(child))) : [] })),
    ...updatedInfant.map(infant => ({ ...infant, SeatDynamic: infant.SeatDynamic && infant.SeatDynamic.length ? infant.SeatDynamic.filter(infant => !(isEmpty(infant))) : [], MealDynamic: infant.MealDynamic && infant.MealDynamic.length ? infant.MealDynamic.filter(infant => !(isEmpty(infant))) : [], Baggage: infant.Baggage && infant.Baggage.length ? infant.Baggage.filter(infant => !(isEmpty(infant))) : [] }))
  ];

  // let phoneNumber = data.phoneCode + data.phoneNumber
  // phoneNumber.replace("+", ""),
  let passengersLength = data.Passengers && data.Passengers.length;

  arr = totalPassengers && totalPassengers.length && totalPassengers.map((item, index) => {
    let baseFare = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.BaseFare || 0;
    let tax = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.Tax || 0;
    let otherCharges = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.OtherCharges || 0;
    if (flightBookedResults && flightBookedResults[key] && flightBookedResults[key].specialfare) {
      baseFare = baseFare / passengersLength;
      tax = tax / passengersLength;
      otherCharges = otherCharges / passengersLength;
    }
    return {
      ...item,
      Fare: {
        "BaseFare": baseFare || 0,
        "Tax": tax || 0,
        "YQTax": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.YQTax || 0,
        "AdditionalTxnFeePub": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeePub || 0,
        "AdditionalTxnFeeOfrd": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeeOfrd || 0,
        "OtherCharges": otherCharges
      },
      "City": "Gurgaon",
      "AddressLine2": item.AddressLine1,
      "PaxType": item.PaxType,
      "CountryCode": "IN",
      "ContactNo": data.phoneNumber,
      "PhoneCode": data.phoneCode,
      "Email": data.email,
      "IsLeadPax": index == 0 ? true : false,
      "FFAirlineCode": "",
      "FFNumber": "",
      "Baggage": item.Baggage || [],
      "MealDynamic": item.MealDynamic || [],
      "SeatDynamic": item.SeatDynamic || [],
      "GSTCompanyAddress": gstDetails.GSTCompanyAddress.trim() || "",
      "GSTCompanyContactNumber": gstDetails.GSTCompanyContactNumber.trim() || "",
      "GSTCompanyName": gstDetails.GSTCompanyName.trim() || "",
      "GSTNumber": gstDetails.GSTNumber.trim() || "",
      "GSTCompanyEmail": gstDetails.GSTCompanyEmail.trim() || "",
      "Gender": item.Title == "Mr" ? "1" : "2"
    }
  })
  return arr;
}

export const generatePassengerObjectNonLCC = (data = [], flightBookedResults, key, meals, gstDetails = {}, seats) => {
  let arr = [];
  let adultFilter = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "1") : [] || []

  let updatedAdult = adultFilter && adultFilter.map((item, index) => {
    return {
      ...item,
      ...(!isEmpty(meals && meals.adult && meals.adult.length && meals.adult[index]) && { "Meal": meals && meals.adult && meals.adult.length && meals.adult[index] }),
      ...(!isEmpty(seats && seats.adult && seats.adult.length && seats.adult[index]) && { "Seat": seats && seats.adult && seats.adult.length && seats.adult[index] }),
    }
  })


  let childFilter = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "2") : [] || []

  let updatedChild = childFilter && childFilter.map((item, index) => {
    return {
      ...item,
      ...(!isEmpty(meals && meals.children && meals.children.length && meals.children[index]) && { "Meal": meals && meals.children && meals.children.length && meals.children[index] }),
      ...(!isEmpty(seats && seats.children && seats.children.length && seats.children[index]) && { "Seat": seats && seats.children && seats.children.length && seats.children[index] }),
    }
  })

  let updatedInfant = data.Passengers && data.Passengers.length ? data.Passengers.filter((item, index) => item.PaxType == "3") : [] || [];

  // let totalPassengers = [
  //   ...updatedAdult.map(adult => ({ ...adult, Seat: adult.Seat && adult.Seat.length ? adult.Seat.filter(adult => !(isEmpty(adult))) : [], Meal: adult.Meal && adult.Meal.length ? adult.Meal.filter(adult => !(isEmpty(adult))) : [] })),
  //   ...updatedChild.map(child => ({ ...child, Seat: child.Seat && child.Seat.length ? child.Seat.filter(child => !(isEmpty(child))) : [], Meal: child.Meal && child.Meal.length ? child.Meal.filter(child => !(isEmpty(child))) : [] })),
  //   ...updatedInfant.map(infant => ({ ...infant, Seat: infant.Seat && infant.Seat.length ? infant.Seat.filter(infant => !(isEmpty(infant))) : [], Meal: infant.Meal && infant.Meal.length ? infant.Meal.filter(infant => !(isEmpty(infant))) : [] }))
  // ];

  let totalPassengers = [
    ...updatedAdult || [],
    ...updatedChild || [],
    ...updatedInfant || []
  ];

  let passengersLength = data.Passengers && data.Passengers.length;

  arr = totalPassengers && totalPassengers.length ? totalPassengers.map((item, index) => {
    let baseFare = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.BaseFare || 0;
    let tax = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.Tax || 0;
    let otherCharges = flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.OtherCharges || 0;
    if (flightBookedResults && flightBookedResults[key] && flightBookedResults[key].specialfare) {
      baseFare = baseFare / passengersLength;
      tax = tax / passengersLength;
      otherCharges = otherCharges / passengersLength;
    }

    return {
      ...item,
      Fare: {
        "Currency": "INR",
        "BaseFare": baseFare || 0,
        "Tax": tax || 0,
        "YQTax": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.YQTax || 0,
        "AdditionalTxnFeePub": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeePub || 0,
        "AdditionalTxnFeeOfrd": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeeOfrd || 0,
        "OtherCharges": otherCharges,
        "Discount": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.Discount || 0,
        "PublishedFare": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.PublishedFare || 0,
        "OfferedFare": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.OfferedFare || 0,
        "TdsOnCommission": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnCommission || 0,
        "TdsOnPLB": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnPLB || 0,
        "TdsOnIncentive": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnIncentive || 0,
        "ServiceFee": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.ServiceFee || 0
      },
      "City": "Gurgaon",
      "AddressLine2": item.AddressLine1,
      "PaxType": item.PaxType,
      "CountryCode": "IN",
      "ContactNo": data.phoneNumber,
      "PhoneCode": data.phoneCode,
      "Email": data.email,
      "IsLeadPax": index == 0 ? true : false,
      "FFAirlineCode": item.FFAirlineCode || "",
      "FFNumber": item.FFNumber || "",
      "GSTCompanyAddress": gstDetails.GSTCompanyAddress.trim() || "",
      "GSTCompanyContactNumber": gstDetails.GSTCompanyContactNumber.trim() || "",
      "GSTCompanyName": gstDetails.GSTCompanyName.trim() || "",
      "GSTNumber": gstDetails.GSTNumber.trim() || "",
      "GSTCompanyEmail": gstDetails.GSTCompanyEmail.trim() || "",
      "Gender": item.Title == "Mr" ? "1" : "2"
    }
  }) : []
  return arr;
}


// export const generatePassengerObjectNonLCC = (data = [], flightBookedResults, key, gstDetails = {}) => {

//   let arr = [];
//   arr = data && data.Passengers && data.Passengers.length ? data.Passengers.map((item, index) => {
//     return {
//       ...item,
//       Fare: {
//         "Currency": "INR",
//         "BaseFare": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.BaseFare || 0,
//         "Tax": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.Tax || 0,
//         "YQTax": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.YQTax || 0,
//         "AdditionalTxnFeePub": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeePub || 0,
//         "AdditionalTxnFeeOfrd": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.AdditionalTxnFeeOfrd || 0,
//         "OtherCharges": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.OtherCharges || 0,
//         "Discount": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.Discount || 0,
//         "PublishedFare": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.PublishedFare || 0,
//         "OfferedFare": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.OfferedFare || 0,
//         "TdsOnCommission": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnCommission || 0,
//         "TdsOnPLB": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnPLB || 0,
//         "TdsOnIncentive": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.TdsOnIncentive || 0,
//         "ServiceFee": flightBookedResults && flightBookedResults[key] && flightBookedResults[key].Fare && flightBookedResults[key].Fare.ServiceFee || 0
//       },
//       "City": "Gurgaon",
//       "AddressLine2": item.AddressLine1,
//       "PaxType": item.PaxType,
//       "CountryCode": "IN",
//       "ContactNo": data.phoneNumber,
//       "Email": data.email,
//       "IsLeadPax": index == 0 ? true : false,
//       "FFAirlineCode": "6E",
//       "FFNumber": "123",
//       "GSTCompanyAddress": gstDetails.GSTCompanyAddress || "",
//       "GSTCompanyContactNumber": gstDetails.GSTCompanyContactNumber || "",
//       "GSTCompanyName": gstDetails.GSTCompanyName || "",
//       "GSTNumber": gstDetails.GSTNumber || "",
//       "GSTCompanyEmail": gstDetails.GSTCompanyEmail || "",
//       "Gender": item.Title == "Mr" ? "1" : "2"
//     }
//   }) : []
//   return arr;
// }

export const generatePassengersForInsuranceBooking = (data = [], phone, email) => {
  let arr = [];
  arr = data && data.length ? data.map((item, index) => {
    return {
      "Title": item && item.Title,
      "FirstName": item && item.FirstName,
      "LastName": item && item.LastName,
      "BeneficiaryTitle": "Mr",
      "BeneficiaryName": "Mr Kapil Sharma",
      "PassportCountry": "IN",
      "RelationShipToInsured": "Self",
      "RelationToBeneficiary": "Spouse",
      "Gender": item && item.Title == "Mr" ? "1" : "2",
      "Sex": item && item.Title == "Mr" ? "1" : "2",
      "DOB": moment(item && item.DateOfBirth).format("YYYY-MM-DD") || "",
      "PassportNo": item && item.PassportNo || "",
      "PhoneNumber": phone || "8860446294",
      "EmailId": email || "",
      "AddressLine1": item && item.AddressLine1 || "",
      "AddressLine2": "delhi",
      "CityCode": "DELHI",
      "CountryCode": "IND",
      "MajorDestination": "Delhi",
      "PinCode": "110045"
    }
  }) : []
  return arr;
}

export const generateRandomString = (length) => {
  var str = '';
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split(
    '');
  var charsLen = chars.length;
  if (!length) {
    length = ~~(Math.random() * charsLen);
  }
  for (var i = 0; i < length; i++) {
    str += chars[~~(Math.random() * charsLen)];
  }
  return str;
};

export const ImageSizeCheck = (mb, data) => {
  return ((mb * 1024 * 1024) > data)
}

export const isAgent = () => {
  const user = getUser();
  if (!user) return false;

  const isAgent = (user && user.info && user.info.accounts_type != "users")
  return isAgent;
}

export const AgentKey = () => {
  return isAgent() ? "pdc_offeredfare" : "PublishedFare"
}

//Function Name: handleLoadAirportOptions
//Parameters: term,callback
//Description: This function is used to find Airports autocomplete.
export const handleLoadAirportOptions = (term, callback, airports = []) => {
  term = term.toLowerCase();
  const filteredAirports = airports
    .filter(airport => {
      const { name, city, country, iata } = airport || {};
      return (
        (iata && iata.toLowerCase().indexOf(term) != -1) ||
        (name && name.toLowerCase().indexOf(term) != -1) ||
        (city && city.toLowerCase().indexOf(term) != -1) ||
        (country && country.toLowerCase().indexOf(term) != -1)
      );
    })
    .sort((a, b) => {
      if (a.iata.toLowerCase() == term) {
        return -1;
      }
      if (b.iata.toLowerCase() == term) {
        return 1;
      }
      return 0;
    })
    .map((i, ind) => ({
      ...i,
      label: (
        <div key={ind}>
          <div>
            {i.city}, {i.country}{" "}
            <span style={{ float: "right" }}>{i.iata}</span>
          </div>
          <span style={{ fontSize: 11 }}>{i.name}</span>
        </div>
      ),
      value: i.iata
    }));
  setTimeout(() => {
    callback(filteredAirports);
  }, 800);
};

//function used to display options for airline selection in case of agent special fares
export const convertAirlineSelectionContent = airports => {
  return airports.map((item, i) => ({
    ...item,
    label: (
      <ul key={i} className='flight-selection-agent'>
        <li className="image-custom">
          <img width={30} src={`https://static.udchalo.com/client_assets/img/airline_logo/${item && item.AirlineCode && item.AirlineCode.substring(0, 2)}.png`} alt='not available' onError={(e) => { e.target.onerror = null; e.target.src = `https://www.priceline.com/sam/air/carrier_logos/airLogo_${item && item.AirlineCode && item.AirlineCode.substring(0, 2)}.png` }} />
          <p>
            <strong>{(item && item.airline) || "Jet"}</strong>
          </p>
        </li>
        <li>
          <p>
            <span>{item && item.flightCode}</span>
          </p>
          <p>
            <span>{item.segments && item.segments[0] && item.segments[0].Airline.AirlineCode} - {item.segments && item.segments[0] && item.segments[0].Airline.FlightNumber}</span>
          </p>
        </li>
        <li>
          <h6 style={{ color: "#000" }}>{item && item.depTime || ""}</h6>
          <p>{item.segments && item.segments[0] && item.segments[0].Origin && item.segments[0].Origin.Airport && item.segments[0].Origin.Airport.AirportCode}</p>
        </li>

        <li className="duration-time">
          <p>{item && item.duration}</p>
          <div className="point-stops">{renderSpan(item && item.segments)}</div>
          <p>
            {item && item.stops == "0" ? "Non-Stop" : `${item && item.stops} Stop`}
          </p>
        </li>

        <li>
          <h6 style={{ color: "#000" }}>{item && item.arrTime}</h6>
          <p>{item.segments && item.segments[item && item.segments && item.segments.length - 1] && item.segments[item && item.segments && item.segments.length - 1].Destination && item.segments[item && item.segments && item.segments.length - 1].Destination.Airport && item.segments[item && item.segments && item.segments.length - 1].Destination.Airport.AirportCode}</p>
        </li>
      </ul>
    ),
    value: item.AirlineCode, ...item
  }));
};


//function used to show duration popover and duration time in airline flight search
export const renderSpan = (segments = []) => {
  return segments.map((item, i) => {
    if (i == 0) {
      return
    }
    return <React.Fragment key={i}>
      <OverlayTrigger key="top" placement="top" overlay={
        <Popover id="tooltip-top" className="popover-flight-detail">
          <div>
            <p>Plane change in</p>
            <p>{item && item.Origin && item.Origin.Airport.CityName}({item.Origin.Airport.CityCode})</p>
          </div>
        </Popover>
      }>
        <span className="point-style cursor-pointer" />
      </OverlayTrigger>
    </React.Fragment>;
  });
};

export const scrollIntoView = (label) => {
  var elmnt = document.getElementById(label);
  if (elmnt) {
    setTimeout(() => {
      elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 500);
  }
}




//utils common function for flight search 
export const sortRenderer = (filter, list) => {
  const updatedList = list.map(flight => {
    const { duration = "" } = flight;
    let hrs,mins;
    if(duration){
    const durations = duration.split(" ");

     hrs = durations[0];
     mins = durations[1];

    if (hrs.length == 2) {
      hrs = `0${hrs}`;
    }
    if (mins.length == 2) {
      mins = `0${mins}`;
    }
  }else{
    hrs=0;
    mins=0;
  }
    return {
      ...flight,
      totalPrice: flight.Fare.PublishedFare,
      duration: `${hrs} ${mins}`
    };
  });
  return _.orderBy(updatedList, filter.sortBy, filter.sortOrder);
};

export const sortRendererForDuplicateFlights = (filter, list) => {
  const updatedList = list.map(flight => {
    let firstObj = [...flight]
    const { duration = "" } = firstObj[0];
    const durations = duration.split(" ");

    let hrs = durations[0];
    let mins = durations[1];

    if (hrs.length == 2) {
      hrs = `0${hrs}`;
    }
    if (mins.length == 2) {
      mins = `0${mins}`;
    }
    let sortedFlights = _.orderBy(flight, "totalPrice", "asc")
    let updatedFirstObj = {
      duplicates: sortedFlights,
      totalPrice: firstObj[0].Fare.PublishedFare,
      duration: `${hrs} ${mins}`,
      depTime: firstObj[0].depTime,
      arrTime: firstObj[0].arrTime
    }

    return updatedFirstObj;
  });

  return _.orderBy(updatedList, filter.sortBy, filter.sortOrder);
};


export const calculateAirlines = (array = []) => {
  let maxValue = 0;
  let minValue = 0;
  let uniqueAirlines = [];
  if (array && array.length) {
    let prices = array && array.length > 0 && array.map((item) => item.Fare.PublishedFare);
    maxValue = Math.max(...prices);
    minValue = Math.min(...prices);
    array.map((item) => uniqueAirlines.push(item.airline));
    let removedDuplicates = [...new Set(uniqueAirlines)]
    let finalArray = [];
    removedDuplicates.map((item) => finalArray.push({ label: item, value: true }))

    return { maxValue, minValue, finalArray };
  }
  return {};
}

export const convertParamsToStateObject = (parseData) => {
  const updatedData = {
    "adults": Number(parseData.A) || 0,
    "children": Number(parseData.C) || 0,
    "iternary": [...convertInternaryToArray(parseData.iternary)],
    "class": parseData.cabinClass,
    "infants": Number(parseData.I) || 0,
    "passengers": parseInt(parseData.A || 1 + parseData.C || 0 + parseData.I || 0),
    "trip": parseData.tripType,
    "international": parseData && parseData.intl || "false",
    "passengertype": parseData && parseData.passengertype || "Normal"
  }
  return { ...updatedData };
}

export const convertInternaryToArray = (iternary) => {
  const arr = iternary.split("_")
  let updatedData = arr.map((item, index) => {
    const data = item && item.split(":")
    return {
      "date": data[0],
      "from": {
        "iata": data[1].split("-")[0],
        "city": data[1].split("-")[1],
        "country": data[1].split("-")[2],
      },
      "to": {
        "iata": data[2].split("-")[0],
        "city": data[2].split("-")[1],
        "country": data[2].split("-")[2],
      }
    }
  })

  let convertedValues = updatedData && updatedData.map((item) => {
    return {
      ...item,
      "from": {
        ...item.from,
        ...convertAirportOption({ value: { ...item.from } })
      },
      "to": {
        ...item.to,
        ...convertAirportOption({ value: { ...item.to } })
      }
    }
  })

  return [...convertedValues]
}


export const multiCityDateHandler = (val, key, name, stateParams) => {
  if (name === "from" || name === "to") {
    val = {
      ...val,
      label: (
        <div>
          <strong>{val.city}</strong>,{" "}
          <span style={{ fontSize: 13 }}>{val.iata}</span>
        </div>
      )
    };
  }
  if (name == "focused") {
    val = val.focused;
  }
  let data = stateParams[key];
  data = data[name] = val;

  if (name == "to" && key + 1 < stateParams.length) {
    let data1 = stateParams[key + 1];
    data1 = data1.from = val;
  }

  if (name == "date" && key + 1 < stateParams.length) {
    let data2,
      i = key;
    let temp = stateParams[i];
    let temp2 = stateParams[i + 1];

    if (moment(temp.date).valueOf() > moment(temp2.date).valueOf()) {
      while (i < stateParams.length - 1) {
        data2 = stateParams[i + 1];
        data2 = data2.date = val;
        i++;
      }
    }
  }
  return stateParams
};

export const handleFlightSearchQueryParams = (arr = []) => {
  let data = arr.map((item, index) => {
    const { from, to, date } = item;
    return `${date}:${from.iata}-${from.city}-${from.country}:${to.iata}-${to.city}-${to.country}${index + 1 == arr.length ? "" : "_"}`
  })
  return data.toString().replace(/,/g, "")
}

export const convertSearchObjectMulticity = (arr = []) => {
  let data = arr.map((item, index) => {
    const { from, to } = item;
    return {
      "from": { iata: from.iata, country: from.country, city: from.city },
      "to": { iata: to.iata, country: to.country, city: to.city },
      "date": moment(item.date).format("YYYY-MM-DD")
    }
  })
  return data
}

export const getMulticityItems = (items, UIcallback, id) => {
  let data = [];
  let i = 0;
  let multicityIndex = 0;
  while (i < items.length) {
    const flight = items[i];
    const isFlightWithoutLayover = !flight.layOvers;
    if (isFlightWithoutLayover) {
      data.push(UIcallback(flight, flight, multicityIndex, [], id))
      ++i;
      ++multicityIndex;
    }
    else {

      const finalLayoverIndex = i + flight.layOvers;
      data.push(UIcallback(flight, items[finalLayoverIndex], multicityIndex, items.filter((item, index) => i < index && index <= finalLayoverIndex), id))
      i = finalLayoverIndex + 1;
      ++multicityIndex;
    }
  }
  return data;
}

export const evaluateTripType = (arr, tripVal) => {
  if (arr.length == 2) {
    if (arr[0].from.city == arr[1].to.city && arr[0].to.city == arr[1].from.city) {
      return "Round trip";
    }
    else {
      return "Multi city";
    }

  }
  else
    return tripVal;
}

export const evaluateJourneyType = (str) => {
  if (str == "One way")
    return "1"
  if (str == "Round trip")
    return "2"
  if (str == "Multi city")
    return "3"
}

export const checkVerticalScroll = () => {
  return (document.documentElement.scrollHeight - window.innerHeight) > 45
  return document.body.scrollHeight > document.body.clientHeight;
}


export const currencySign = (val = '') => {
  return <React.Fragment>
    <span className="currencySign">
      <i className="fal fa-rupee-sign" />
    </span>
  </React.Fragment>
  // ₹
}

export function currencySignExt(val = '', fontsize = '') {
  return <i style={{ fontSize: fontsize && fontsize }} className="fal fa-rupee-sign" />
}

// this function is used for evaluating Plan coverage in case of insurance api call
export const evaluatePlanCoverage = (iternary = []) => {
  const { from, to } = iternary && iternary.length && iternary[0]
  const origin = from && from.country || "India";
  const destination = to && to.country || "India";

  //case handled for domestic, domestic to US , Overseas to India
  if (origin == "India" && destination == "India" || origin == "India" && destination == "United States" || origin != "United States" && destination == "India") {
    return 4;
  }

  // case handled for US-India , US-US
  else if (origin == "United States" && destination == "India" || origin == "United States" && destination == "United States") {
    return 1;
  }

  // case handled for India-Worldwide except India and US
  else if (origin == "India" && destination != "United States" && destination != "India") {
    return 2;
  }

  //case handled for Worldwide travel except India and US
  else if (origin != "India" && origin != "United States" && destination != "United States" && destination != "India") {
    return 3;
  }

  //case handled for India-NonUS
  else if (origin == "India" && destination != "United States" && destination != "India") {
    return 1;
  }

  //default Case
  return 4;

}

export const updatedAmountWithCardFee = (amount, activeTab, cardPercentage) => {
  if (activeTab == "DebitCard") {
    return (parseFloat(amount) + ((parseFloat(cardPercentage.debit_gateway_charge) * parseFloat(amount)) / 100) + ((parseFloat(cardPercentage.debit_gateway_charge) * parseFloat(amount)) / 100) * (.18))

  }
  if (activeTab == "CreditCard") {
    return (parseFloat(amount) + ((parseFloat(cardPercentage.credit_gateway_charge) * parseFloat(amount)) / 100) + ((parseFloat(cardPercentage.credit_gateway_charge) * parseFloat(amount)) / 100) * (.18))
  }
  return
}

export const checkProcessingFee = (departFare, returnFare, totalMealsPrice, totalBaggagePrice, totalSeatsPrice, PDC_ProcessingFee, transactionType, cardPercentage,isCouponApplied=false) => {

  let ProcessingFee = PDC_ProcessingFee;
  let AgentDiscount = parseFloat(departFare && departFare.OfferedFare || 0) + parseFloat(returnFare && returnFare.OfferedFare || 0)


  let TBOAmount = parseFloat(departFare && departFare.PublishedFare || 0) +
    parseFloat(returnFare && returnFare.PublishedFare || 0) +
    parseFloat(totalMealsPrice || 0) +
    parseFloat(totalBaggagePrice || 0) +
    parseFloat(totalSeatsPrice || 0) +
    parseFloat(ProcessingFee || 0)


  let percentage = 0;
  let increasedFee = 0;

  if (transactionType === "DebitCard") {
    percentage = parseFloat(cardPercentage && cardPercentage.debit_gateway_charge) / 100
  }
  if (transactionType === "CreditCard") {
    percentage = parseFloat(cardPercentage && cardPercentage.credit_gateway_charge) / 100
  }

  if (transactionType === "NetBanking") {
    if(isAgent()){
      percentage = parseFloat(cardPercentage && cardPercentage.nb_gateway_charge)
      return percentage
      }else{
        percentage = parseFloat(cardPercentage && cardPercentage.nb_gateway_charge_b2c) / 100
      }
  }
  if(transactionType === "UPI"){
    percentage =0;
    return percentage;
  }

  if (isAgent()) {
    ProcessingFee = 0;
  //   TBOAmount = TBOAmount - parseFloat(AgentDiscount || 0)
  }
if(!isAgent()){
  if(isCouponApplied == true){
    increasedFee = (((TBOAmount * percentage) * 0.18) + (TBOAmount * percentage)) - ProcessingFee;
    return increasedFee
  }
  else if ((TBOAmount * percentage) > ProcessingFee) {
    increasedFee = (((TBOAmount * percentage) * 0.18) + (TBOAmount * percentage)) - ProcessingFee;
    return increasedFee
  }
}else{

  increasedFee = (((TBOAmount * percentage) * 0.18) + (TBOAmount * percentage)) - ProcessingFee;
  return increasedFee

}
  return increasedFee
}

export const checkProcessingFeePDCPAID = (departFare, returnFare, totalMealsPrice, totalBaggagePrice, totalSeatsPrice, PDC_ProcessingFee, transactionType, cardPercentage,isCouponApplied=false) => {
if(isCouponApplied == true){
  return 0;
}
  let ProcessingFee = PDC_ProcessingFee;
  let AgentDiscount = parseFloat(departFare && departFare.OfferedFare || 0) + parseFloat(returnFare && returnFare.OfferedFare || 0)


  let TBOAmount = parseFloat(departFare && departFare.PublishedFare || 0) +
    parseFloat(returnFare && returnFare.PublishedFare || 0) +
    parseFloat(totalMealsPrice || 0) +
    parseFloat(totalBaggagePrice || 0) +
    parseFloat(totalSeatsPrice || 0) +
    parseFloat(ProcessingFee || 0)


  let percentage = 0;
  let increasedFee = 0;
  if (transactionType === "DebitCard") {
    percentage = parseFloat(cardPercentage && cardPercentage.debit_gateway_charge) / 100
  }
  if (transactionType === "CreditCard") {
    percentage = parseFloat(cardPercentage && cardPercentage.credit_gateway_charge) / 100
  }

  if (transactionType === "NetBanking") {
    if(!isAgent()){
      percentage = parseFloat(cardPercentage && cardPercentage.nb_gateway_charge_b2c)/100
    }
  }
  if(transactionType === "UPI"){
    percentage =0;
    return percentage;
  }

  if (isAgent()) {
    ProcessingFee = 0;

  }
if(!isAgent()){
  if ((TBOAmount * percentage) >= ProcessingFee) {
    increasedFee = (((TBOAmount * percentage) * 0.18) + (TBOAmount * percentage)) -ProcessingFee;
    if(increasedFee < 0){
      increasedFee =0;
    }
    return increasedFee
  }else{
    increasedFee = ProcessingFee- (((TBOAmount * percentage) * 0.18) + (TBOAmount * percentage));
    if(increasedFee < 0){
      increasedFee =0;
    }
    return increasedFee
  }
}else{
 
  return 0;
}
  
}


export const CurrentTZ = () => {
  return momentTZ.tz.guess();
}

export const GreenwichTZ = (date) => {
  return moment.unix(date).tz(CurrentTZ());
}

export const evaluateTotalInFareSummary = (arr, key) => {
  let totalCost = arr && arr.length ? arr.map((item, index) => item.Details.FlightItinerary.Fare[key]) : 0;
  return totalCost && totalCost.length ? totalCost.reduce((a, b) => parseFloat(a) + parseFloat(b)) : 0;
}

export const calenderPriceShow = (price) => {
  const Count = ['', 'K', 'M', 'G'];
  const i = 0 === price ? price : Math.floor(Math.log(price) / Math.log(1000));
  let result = parseFloat((price / Math.pow(1000, i)).toFixed(1));
  result += `${Count[i]}`;
  return result;
}

export const truncate = (str, no_words) => {
  return str.split(" ").splice(0, no_words).join(" ");
}

export const findDuplicatesFlightsNo = (filter, data) => {
  let flight_num = data.map((item) => {
    let segment = item.segments || [];
    let string = "";
    segment.map((val, index) => {
      let sign = segment.length == index ? "" : "/"
      string += val.Airline.AirlineCode + "-" + val.Airline.FlightNumber + sign
      return string
    })
    if (item.specialfare) return
    return string;
  })

  // Find the duplicate value Indexes

  let duplicate_index = [];
  let unique_index = [];
  flight_num.map((item, index) => {

    let index_of = flight_num.indexOf(item);
    let last_index_of = flight_num.lastIndexOf(item);

    if (index_of !== last_index_of && index != last_index_of) {
      duplicate_index.push({ index: index, val: item });
      if (!duplicate_index.some(i => i.index == last_index_of)) {
        duplicate_index.push({ index: last_index_of, val: flight_num[last_index_of] });
      }
      return false;
    }
    if (index_of == last_index_of && index == last_index_of) {
      unique_index.push({ index: index, val: item })

    }

  })

  // Find the duplicate values

  let resulted_array = [];

  duplicate_index.map((item) => {
    resulted_array.push({ ...data[item.index], airlinecode: item.val })
  })

  // Find the Unique values  
  let resultedUnique_array = []

  unique_index.map((item) => {
    // resultedUnique_array.push([{ ...data[item.index], airlinecode: item.val }])
    resultedUnique_array.push({
      depTime: data[item.index].depTime,
      arrTime: data[item.index].arrTime,
      duration: data[item.index].duration,
      totalPrice: data[item.index].totalPrice,
      duplicates: [data[item.index]]
    })
  })


  let i = 0, length = resulted_array.length || 0;
  let final_Array = [];

  while (i < length) {
    let newArr = [];

    for (let j = i; j <= length - 1; j++) {
      if (resulted_array[i].airlinecode == resulted_array[j].airlinecode) {
        newArr.push(resulted_array[j])
      }
      /* else {
        i = j - 1;
        final_Array.push(newArr);
        break;
      }
      if (i == length - 1 && newArr.length) {
        final_Array.push(newArr);
      } */
    }
    if (newArr.length != 1) {
      let newObj = {
        depTime: newArr[0].depTime,
        arrTime: newArr[0].arrTime,
        duration: newArr[0].duration,
        totalPrice: newArr[0].totalPrice,
        duplicates: newArr,
      }
      final_Array.push(newObj);
      //i += newArr.length;
      i++;
    }
    else {
      ++i;
    }

  }

  let allResultsIn2D = [...final_Array, ...resultedUnique_array]

  // return allResultsIn2D || []
  return _.orderBy(allResultsIn2D, filter.sortBy, filter.sortOrder)
}

export const getAgeFromDate = (date) => {
  var birthdate = new Date(date);
  var cur = new Date();
  var diff = cur - birthdate; // This is the difference in milliseconds
  let months = moment().diff(moment(date), "month")
  // alert(months)

  return months;
}

export const getSelectedCouponObj = (coupon, allCoupon) => {
  let couponArr = allCoupon.filter((val) => (val.promocode).toLowerCase() === (coupon).toLowerCase())
  return couponArr || []
}

export const calculateCouponDiscount = (coupon, amount, allCoupon = []) => {
  if (coupon && !coupon.length) {
    return 0
  }

  let couponArr = getSelectedCouponObj(coupon, allCoupon)

  if (couponArr && !couponArr.length) return 0

  let couponObj = couponArr[0]

  if (parseFloat(couponObj.max_ticket) > parseFloat(amount)) return 0

  if (couponObj.discount_type === "price") {
    return parseInt(couponObj.max_discount)
  }

  let discount = parseFloat(couponObj.percentage_value);
  let value = ((amount * discount) / 100)

  return value < parseInt(couponObj.max_discount) ? value : parseInt(couponObj.max_discount)
}

export const hdfcPaymentEncryptRequest =(str) =>{

          let encoded = "";
          let i;
          for (i=0; i<str.length;i++) {
              let a = str.charCodeAt(i);
              let b = a ^ hdfc_request_encrypt_code;    // bitwise XOR with any number, e.g. 123
              encoded = encoded+String.fromCharCode(b);
          }
          // return hdfc_request_encrypt_code;
          return encoded;
};

